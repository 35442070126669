import React from "react";
import { motion } from "framer-motion";
function SkillCard({ text, bg_color }) {
  return (
    <motion.div
      style={{ color: "white" }}
      initial={{ opacity: 0, scale: 0.8 }} // Initial state
      animate={{ opacity: 1, scale: 1 }} // Final state
      transition={{ duration: 1, ease: "easeOut" }} // Transition settings
    >
      <p
        style={{
          padding: "18px",
          backgroundColor: bg_color,
          textAlign: "center",
          margin: "10px",
          width: "50%",
          borderRadius: "12px",
          fontSize: "18px",
        }}
      >
        {text}
      </p>
    </motion.div>
  );
}

export default SkillCard;
