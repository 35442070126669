import React from "react";
import "./Home.css";
import SkillCard from "./SkillCard";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Arsh_Pic from "../logo.png";
import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";

function Home() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  return (
    <div className="main">
      <div id="Home_Page">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="arsh_profile"
        >
          <img
            src={Arsh_Pic}
            alt="Arsh Developer"
            className="developer_image"
            style={
              isTabletOrMobile
                ? {
                    height: "250px",
                    width: "auto",
                    overflow: "hidden",
                    display: "block",
                    objectFit: "cover",
                    paddingTop: "200px",
                  }
                : {}
            }
          />
        </motion.div>
        <div className="arsh_info">
          <motion.p
            className="intro"
            initial={{ y: "0px", opacity: 0, color: "Grey" }}
            animate={{ y: "12px", opacity: 1, color: "Black" }}
            transition={{ duration: 1 }}
          >
            My name
            <br />
            is <span className="arsh_name">Arsh</span>
            <Typewriter
              options={{
                strings: ["Software Developer", "Business Analyst"],
                autoStart: true,
                loop: true,
              }}
            />
          </motion.p>
          <motion.p
            className="Bio"
            initial={{ y: "0px", opacity: 0 }}
            animate={{ y: "12px", opacity: 1 }}
            transition={{ duration: 1.2 }}
          >
            “Code like it's a final version, because every line you write
            contributes to the legacy you leave behind”
          </motion.p>
          <br />
          <Link to="contact" className="hire_me_btn">
            Hire Me
          </Link>
          <br />
          <div className="Skills">
            <SkillCard text="c++" bg_color="#ff8e3d" />
            <SkillCard text="Python" bg_color="#fe5b02" />
            <SkillCard text="Javascript" bg_color="black" />
            <SkillCard text="php" bg_color="#c5c5c5" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
