import React from "react";
import { Link } from "react-router-dom";
function NavLink({ aClassName, mclassName, onClick }) {
  return (
    <div className={mclassName}>
      <Link to="about" className={aClassName} onClick={onClick}>
        About Me
      </Link>

      <br />
      <Link to="resume" className={aClassName} onClick={onClick}>
        Resume
      </Link>
      <br />
      <Link to="projects" className={aClassName} onClick={onClick}>
        Projects
      </Link>
      <br />
      <Link to="chat" className={aClassName} onClick={onClick}>
        Beta Chat
      </Link>
    </div>
  );
}

export default NavLink;
