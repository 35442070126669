import "./App.css";
import Navbar from "./common/Navbar";
import Home from "./common/Home";
import Contact from "./common/Contact";
import About from "./common/About";
import Resume from "./common/Resume";
import Projects from "./common/Projects";
import Chat from "./common/Chat";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/resume" element={<Resume />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/chat" element={<Chat />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
