import React, { useState } from "react";
import { FaUser, FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import "./Contact.css";
import { motion } from "framer-motion";

function Contact() {
  const [activeInputs, setActiveInputs] = useState({});

  const handleFocus = (index) => {
    setActiveInputs((prev) => ({ ...prev, [index]: true }));
  };

  const handleBlur = (index) => {
    setActiveInputs((prev) => ({ ...prev, [index]: false }));
  };

  const inputFields = [
    {
      icons: <FaUser className="user_input_icon" />,
      type: "text",
      title: "Name",
      name: "name1",
      placeholder: "Enter name",
    },
    {
      icons: <MdEmail className="user_input_icon" />,
      type: "email",
      title: "Email",
      name: "email",
      placeholder: "Enter email",
    },
    {
      icons: <FaPhoneAlt className="user_input_icon" />,
      type: "number",
      title: "Phone",
      name: "phone",
      placeholder: "Enter phone no",
    },
  ];

  return (
    <motion.div
      id="main_contact"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="form_input_main">
        <h3>GET IN TOUCH</h3>
        <p>Do you have any idea or question?</p>
        <form action="post">
          {inputFields.map((field, index) => (
            <div
              key={index}
              className="inputGroup"
              style={{
                borderBottom: activeInputs[index] ? "2px solid grey" : "none",
                backgroundColor: activeInputs[index]
                  ? "#ffcaa4"
                  : "rgba(255, 255, 255, 0)",
              }}
            >
              {field.icons}
              <div className="inputField_info">
                <p className="user_title_help">{field.title}</p>
                <input
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  className="main_input"
                  onFocus={() => handleFocus(index)}
                  onBlur={() => handleBlur(index)}
                  style={{
                    backgroundColor: activeInputs[index]
                      ? "#fcb685"
                      : "rgba(255, 255, 255, 0)",
                  }}
                  required
                />
              </div>
            </div>
          ))}
          <textarea
            name="issue"
            placeholder="Describe the Idea or Isue"
            className="issue"
          ></textarea>
          <br />
          <div className="submitgroup">
            <input type="submit" value="Send" className="send_btn" />
          </div>
        </form>
      </div>
    </motion.div>
  );
}

export default Contact;
