import React from "react";

function ImageGrey({ source }) {
  // Inline styles are defined here
  const imgStyle = {
    filter: "grayscale(100%)", // Converts the image to black and white
    width: "auto", // Example width
    height: "40px", // Maintain aspect ratio
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={source}
        alt="NYU logo"
        style={imgStyle} // Apply inline styles
      />
    </div>
  );
}

export default ImageGrey;
