import React, { useState } from "react";
import "./Navbar.css";
import logo from "../logo.svg";
import { motion, AnimatePresence } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { MdMenu, MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import NavLink from "./NavLink";
import NetworkStatus from "./NetworkStatus";

function Navbar() {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1025px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <motion.div
        className="Navbar_main"
        initial={{ y: "0px" }}
        animate={{ y: "12px" }}
        transition={{ duration: 0.5 }}
      >
        {isBigScreen && <NavLink aClassName="nav_item" mclassName="Nav" />}

        {isTabletOrMobile && (
          <div>
            {isMenuOpen ? (
              <MdClose className="Menu_icons" onClick={toggleMenu} />
            ) : (
              <MdMenu className="Menu_icons" onClick={toggleMenu} />
            )}
          </div>
        )}

        <div className="logo">
          <Link to="/">
            <img src={logo} alt="arsh logo" className="logo_img" />
          </Link>
        </div>
        <NetworkStatus />
        <div className="contact">
          <Link to="contact" className="nav_item" id="contact_btn">
            Contact Me
          </Link>
        </div>
      </motion.div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ y: "0px", opacity: 0 }}
            animate={{ y: "12px", opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ y: "0px", opacity: 0 }}
            className="mobile_nav"
          >
            <NavLink
              aClassName="mnav_item"
              mclassName="MobileNav"
              onClick={toggleMenu}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default Navbar;
