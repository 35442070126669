import React from "react";

function Age({ dob }) {
  const today = new Date();

  // Create a Date object for the date of birth
  const birthDate = new Date(dob);

  // Calculate the age in years
  let age = today.getFullYear() - birthDate.getFullYear();

  // Adjust age if the birthday hasn't occurred yet this year
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return <>{age}</>;
}

export default Age;
