import React, { useState, useEffect } from "react";
import "./Chat.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ASCII Art for Terminal
const asciiArt = `
    _             _      __        __         _     _ 
   / \\   _ __ ___| |__   \\ \\      / /__  _ __| | __| |
  / _ \\ | '__/ __| '_ \\   \\ \\ /\\ / / _ \\| '__| |/ _\` |
 / ___ \\| |  \\__ \\ | | |   \\ V  V / (_) | |  | | (_| |
/_/   \\_\\_|  |___/_| |_|    \\_/\\_/ \\___/|_|  |_|\\__,_|

`;

function Chat() {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);

  // Static information for the 'about me' command
  const aboutArsh = `
  MD Arsh is an enthusiastic IT professional
  currently pursuing a Bachelor of Computer Applications (BCA) at JIMS Vasant Kunj, IP University.
  He previously completed one year in a Bachelor of Commerce program focusing on Business Process Management at Delhi Skill and Entrepreneurship University.

  Education :
  - Bachelor of Computer Applications (BCA) from JIMS, Vasant Kunj (IP University).
  - Completed one year in Bachelor of Commerce (Business Process Management) from Delhi Skill and Entrepreneurship University.

  Certifications :
  - MERN Full-Stack Web Development
  - AI Text Summarizer Application
  - Mastering Figma
  - Information Technology Fundamentals
  - And several others

  Projects :
  - NodeCart : An eCommerce platform
  - Golden Lotus Bank : A banking website

  Volunteer Experience :
  - Participated in various volunteer activities, including a Faculty Development Program at DSEU University.

  MD Arsh is a dedicated learner with a strong passion for both technology and business, continuously seeking new opportunities to expand his expertise.
  `;

  // Process user input and generate a response
  const processInput = (input) => {
    let response;
    const lowerCaseInput = input.trim().toLowerCase();

    // Simple command handling
    switch (lowerCaseInput) {
      case "hello":
        response = "Hi there! How can I help you?";
        break;
      case "date":
        response = new Date().toLocaleString();
        break;
      case "help":
        response = `Available commands:
        - hello: Greet the system
        - date: Show the current date and time
        - help: Show this help message
        - github: Show my GitHub profile information
        - linkedin: Show my LinkedIn profile information
        - youtube: Show my YouTube channel link
        - about arsh: Show information about MD Arsh
        - cls: Clear the terminal`;
        break;
      case "github":
        response = "My GitHub profile: https://github.com/arshc0de";
        break;
      case "linkedin":
        response = "My LinkedIn profile: https://in.linkedin.com/in/arshmd";
        break;
      case "youtube":
        response = "My YouTube channel: https://www.youtube.com/@Code.ByArsh";
        break;
      case "about arsh":
        response = aboutArsh;
        break;
      case "cls":
      case "clear":
        setMessages([]); // Clear all messages
        return; // No response needed for this command
      default:
        response = "Unknown command. Type 'help' for a list of commands.";
    }
    return response;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (newline)

      // Add the user's message to the list
      const userMessage = { id: messages.length + 1, text: `> ${inputValue}` };
      setMessages([...messages, userMessage]);

      // Process input and add system response
      const systemResponse = processInput(inputValue);
      if (systemResponse) {
        const responseMessage = {
          id: messages.length + 2,
          text: systemResponse,
        };
        setMessages([...messages, userMessage, responseMessage]);
      }

      // Clear the input field
      setInputValue("");
    }
  };

  useEffect(() => {
    // Initialize the terminal with ASCII art
    const initialMessage = { id: 0, text: asciiArt };
    setMessages([initialMessage]);

    toast("🧑‍💻 Feature Under Development!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      pauseOnHover: false,
      draggable: true,
      theme: "dark",
    });
  }, []);
  return (
    <div id="chat">
      <ToastContainer />
      <div id="terminal">
        <div id="chat_terminal">
          <div id="messages">
            {messages.map((message) => (
              <div key={message.id} className="chat-message">
                <pre>{message.text}</pre>
              </div>
            ))}
          </div>
          <div className="input-container">
            <span id="main_terminal_head">#root-@contact : </span>
            <input
              type="text"
              id="chat_input"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyPress}
              autoFocus
            />
          </div>
        </div>
      </div>
      <div id="commands">
        <p className="command_panel">
          <span className="command_head">Commands :</span> <br />
          <span className="command_head">- hello</span>: Greet the system <br />
          <span className="command_head">- date</span>: Show the current date
          and time <br />
          <span className="command_head">- help</span>: Show this help message{" "}
          <br />
          <span className="command_head">- github</span>: Show my GitHub profile
          information <br />
          <span className="command_head">- linkedin</span>: Show my LinkedIn
          profile information <br />
          <span className="command_head">- youtube</span>: Show my YouTube
          channel link <br />
          <span className="command_head">- about arsh</span>: Show information
          about MD Arsh <br />
          <span className="command_head">- cls</span>: Clear the terminal
        </p>
      </div>
    </div>
  );
}

export default Chat;
