import React, { useState, useEffect } from "react";
import { BsWifi, BsWifiOff } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Toast messages
  const Online_message = () => toast.success("Back Online");
  const Offline_message = () => toast.error("Check your internet");

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      Online_message(); // Trigger the toast when going online
    };
    const handleOffline = () => {
      setIsOnline(false);
      Offline_message(); // Trigger the toast when going offline
    };

    // Add event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const ele_colour = {
    height: "30px",
    width: "30px",
    color: isOnline ? "Green" : "Red",
    backgroundColor: isOnline ? "#A1D6B2" : "#FF8A8A",
    borderRadius: "12px",
    padding: "10px",
  };

  return (
    <div>
      <ToastContainer />
      {isOnline ? (
        <BsWifi style={ele_colour} />
      ) : (
        <BsWifiOff style={ele_colour} />
      )}
    </div>
  );
};

export default NetworkStatus;
