import React from "react";
import "./About.css";
import Age from "./Age";
import NewLogo from "../newlogo.jpg";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

function About() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const mainStPro = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  };

  const aboutMeSt = {
    width: "100%",
    margin: "50px",
  };

  const mainAboutSt = {
    marginTop: "300px",
  };

  return (
    <div id="main_about" style={isTabletOrMobile ? mainAboutSt : {}}>
      <div id="about" style={isTabletOrMobile ? mainStPro : {}}>
        <motion.div
          className="about_me_cards"
          style={isTabletOrMobile ? aboutMeSt : {}}
          initial={{ y: "0px", opacity: 0 }}
          whileInView={{ opacity: 1, y: "12px" }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <div className="image_conatin">
            <img src={NewLogo} alt="Arsh image" className="arsh_image" />
          </div>
          <br />
          <p>
            I'm a constant learner with a passion for Technology and Business.
            I'm always looking for new ways to expand my knowledge. Currently,
            pursuing BCA from JIMS, Vasant Kunj (IP University). I'm confident
            in my ability to learn new skills and to apply them to my work. I'm
            also a quick learner and I'm able to adapt to new situations
            quickly.
          </p>
        </motion.div>
        <motion.div
          className="about_me_cards"
          style={isTabletOrMobile ? aboutMeSt : {}}
          initial={{ y: "0px", opacity: 0 }}
          whileInView={{ opacity: 1, y: "12px" }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true, amount: "all" }}
        >
          <div className="image_conatin">
            <img src={NewLogo} alt="Arsh image" className="arsh_image" />
          </div>
          <br />
          <div id="table-conatiner">
            <table>
              <tbody>
                <tr>
                  <td className="table_cols">
                    <ul>
                      <li className="personal_info">Birthday: 13.08.2004</li>
                      <li className="personal_info">City: New Delhi, Delhi</li>
                      <li className="personal_info">Degree: Pursuing(BCA)</li>
                      <li className="personal_info">Mail : arsh@arsh.com</li>
                    </ul>
                  </td>
                  <td className="table_cols">
                    <ul>
                      <li className="personal_info">
                        Age: <Age dob="2004-08-13" />
                      </li>
                      <li className="personal_info">Study : IP University</li>
                      <li className="personal_info">Website: www.arshdev.me</li>
                      <li className="personal_info">Phone: +91 XXXXXXXXXXX</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </motion.div>
        <motion.div
          className="about_me_cards"
          style={isTabletOrMobile ? aboutMeSt : {}}
          initial={{ y: "0px", opacity: 0 }}
          whileInView={{ opacity: 1, y: "12px" }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true, amount: "all" }}
        >
          <p id="edu_head">EDUCATION</p>
          <ul>
            <li className="personal_info university">
              Guru Gobind Singh Indraprastha University
            </li>
            <ul>
              <li className="personal_info uni_sub">
                Bachelor of Computer Application
              </li>
              <li className="personal_info uni_sub">New Delhi, India</li>
            </ul>
            <li className="personal_info university">
              Delhi Skill and Entrepreneurship University
            </li>
            <ul>
              <li className="personal_info uni_sub">
                Bachelor of Commerce(BPM)
              </li>
              <li className="personal_info uni_sub">Only one Year</li>
              <li className="personal_info uni_sub">New Delhi, India</li>
            </ul>
          </ul>
        </motion.div>
      </div>
    </div>
  );
}

export default About;
